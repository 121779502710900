<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";
import {
  connectToServerApi
} from "@/utils/agGridUtils";
import Warehouses from '@/components/common/Warehouses.vue';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);
const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";

const {
  fetchProduct,
  product,
} = useProducts();

const route = useRoute();
const themeStore = useThemeStore();
let gridApi;
let context = ref(null);

const TRANSACTIONS_URL = `/products/${route.params.id}/inventory_transactions`;

onBeforeMount(async () => {
  await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
  addFuncsToColDefs();
});

const onGridReady = async (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: TRANSACTIONS_URL,
    filterKey: PRODUCT_EXTERNAL_FILTERS_KEY,
    advancedFilterKeys: []
  }
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const columnDefs = [
  { field: "id", headerName: "ID", cellDataType: "text" },
  { field: "created_at", headerName: "Date", cellDataType: "text" },
  {
    field: "order_type_id",
    headerName: "Type",
    cellDataType: "text",
    cellRenderer: function (params) {
      console.log(params);
      if (params.data.order_type_id === 1) {
        return (
          '<a href="/inbound-orders/' +
          params.data.order_id +
          '" target="_blank">' +
          "IN-" +
          params.data.order_id +
          "</a>"
        );
      } else if (params.data.order_type_id === 2) {
        return (
          '<a href="/outbound-orders/' +
          params.data.order_id +
          '" target="_blank">' +
          "OUT-" +
          params.data.order_id +
          "</a>"
        );
      } else {
        return '<a href=""';
      }
    },
  },
  { field: "qty", headerName: "Qty", cellDataType: "text" },
  {
    field: "warehouseOrBin",
    headerName: "Warehouse / Bin",
    cellDataType: "text",
    cellRenderer: function (params) {
      let url = "";

      if (params.data.bin) {
        url = "/bin/" + params.data.bin.id;
      } else {
        url = "_";
      }

      let warehouseNickname = params.data?.warehouse?.nickname;
      let binUrl =
        "<a href='" + url + "' target = '_blank'>" + params.data.bin?.name + "</a>";

      if (warehouseNickname != null && params.data.bin?.name != null) {
        return warehouseNickname + " / "+ binUrl;
      } else if (warehouseNickname != null) {
        return warehouseNickname;
      } else if (params.data.bin?.name != null) {
        return binUrl;
      } else {
        return "";
      }
    },
  },
  { field: "created_by.name", headerName: "User", cellDataType: "text" }, //missing
  { field: "notes", headerName: "Notes", cellDataType: "text" },
];

const valueGetters = {
  qty: (params) => {
    return params.data.qty + " " + product.value.units;
  },
};

const addFuncsToColDefs = function () {
  for (let def of columnDefs) {
    let colName = def.field;
    if (valueGetters[colName]) {
      def.valueGetter = valueGetters[colName];
    }
  }
};

addFuncsToColDefs();

const defaultColDef = {
  sortable: true,
  suppressHeaderMenuButton: true,
  width: 120,
};

const gridOptions = {
  rowModelType: "serverSide",
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
};

const warehouseFiltersChangedCallback = () => {
  gridApi.onFilterChanged();
}

</script>

<template>
  <div id="grid-wrapper">
    <Warehouses class="mb-6" :callBack="warehouseFiltersChangedCallback" :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY" />
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4 class="text-center">Transactions</h4>
      </div>
      <div class="inventory-table d-flex">
        <AgGridVue
          style="width: 100%; height: 100%"
          :class="themeStore.agTheme"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :context="context"
        >
        </AgGridVue>
      </div>
    </ProductLayout>
  </div>
</template>

<style>
.inventory-table {
  height: 600px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
